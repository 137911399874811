<template>
    <div class="page_container" :class="{'straight_length': borderPosition}">
        <div class="page_head">
            <div class="page_head-item">
                <div class="page_head-title">
                    SUBSCRIPTION PAYMENT
                </div>
            </div>
        </div>
        <div class="cards_content">
            <div class="card_item">
                <div class="payment_content">
                    <div class="card_item-title">LITE</div>
                    <div class="card_item-line"></div>
                    <div class="card_item-price">$ {{getPrice(litePlanData) || 0}}</div>
                    <div class="card_item-text">per month</div>
                    <p class="card_item-plus">Plus $300 minimum ad budget</p>
                    <div class="card_item-buyer-seller">
                        <b-form-radio-group v-model="buyer_seller" class="card_item-radio-group" :options="options"
                                            name="buyer-validation">
                        </b-form-radio-group>
                        <div class="buyer_seller-item">
                            <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" fill="#4FA7F8"
                                 class="bi bi-check-lg" viewBox="0 0 16 16">
                                <path d="M12.736 3.97a.733.733 0 0 1 1.047 0c.286.289.29.756.01 1.05L7.88 12.01a.733.733 0 0 1-1.065.02L3.217 8.384a.757.757 0 0 1 0-1.06.733.733 0 0 1 1.047 0l3.052 3.093 5.4-6.425a.247.247 0 0 1 .02-.022Z"/>
                            </svg>
                            Software for Real Estate Agents
                        </div>
                        <div class="buyer_seller-item">
                            <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" fill="#4FA7F8"
                                 class="bi bi-check-lg" viewBox="0 0 16 16">
                                <path d="M12.736 3.97a.733.733 0 0 1 1.047 0c.286.289.29.756.01 1.05L7.88 12.01a.733.733 0 0 1-1.065.02L3.217 8.384a.757.757 0 0 1 0-1.06.733.733 0 0 1 1.047 0l3.052 3.093 5.4-6.425a.247.247 0 0 1 .02-.022Z"/>
                            </svg>
                            12 month contract
                        </div>
                    </div>
                </div>

                <div class="card_item-agree-btn">
                    <div class="card_item-agree_check">
                        <div class="card_item-agreement">
                            <b-form-checkbox v-model="litePlan" name="check-button">
                                I have read and agree to the
                            </b-form-checkbox>&nbsp; <span @click="buyerOrSeller()"> Agreement</span>

                        </div>
                        <div class="card_item-agreement">
                            <b-form-checkbox v-model="litePlanPolicies" name="check-button1">
                                I have read and agree to the
                            </b-form-checkbox>&nbsp; <span @click="liteBillingPolicies()"> Billing Policies</span>

                        </div>
                    </div>

                    <b-button class="subscribe_btn" :disabled="litePlanCheck" @click="subscribeLite()">Subscribe
                    </b-button>
                </div>
            </div>
            <div class="card_item">
                <div class="payment_content">
                    <div class="card_item-title">PRO</div>
                    <div class="card_item-line"></div>
                    <div class="card_item-price">$ {{getPrice(proPlanData) || 0}}</div>
                    <div class="card_item-text">per month</div>
                    <p class="card_item-plus">Plus $300 minimum ad budget</p>
                    <div class="card_item-buyer-seller">
                        <div class="buyer_seller-item">
                            <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" fill="#4FA7F8"
                                 class="bi bi-check-lg" viewBox="0 0 16 16">
                                <path d="M12.736 3.97a.733.733 0 0 1 1.047 0c.286.289.29.756.01 1.05L7.88 12.01a.733.733 0 0 1-1.065.02L3.217 8.384a.757.757 0 0 1 0-1.06.733.733 0 0 1 1.047 0l3.052 3.093 5.4-6.425a.247.247 0 0 1 .02-.022Z"/>
                            </svg>
                            Buyer And Seller Leads
                        </div>
                        <div class="buyer_seller-item">
                            <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" fill="#4FA7F8"
                                 class="bi bi-check-lg" viewBox="0 0 16 16">
                                <path d="M12.736 3.97a.733.733 0 0 1 1.047 0c.286.289.29.756.01 1.05L7.88 12.01a.733.733 0 0 1-1.065.02L3.217 8.384a.757.757 0 0 1 0-1.06.733.733 0 0 1 1.047 0l3.052 3.093 5.4-6.425a.247.247 0 0 1 .02-.022Z"/>
                            </svg>
                            Software For Real Estate Agents
                        </div>
                        <div class="buyer_seller-item">
                            <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" fill="#4FA7F8"
                                 class="bi bi-check-lg" viewBox="0 0 16 16">
                                <path d="M12.736 3.97a.733.733 0 0 1 1.047 0c.286.289.29.756.01 1.05L7.88 12.01a.733.733 0 0 1-1.065.02L3.217 8.384a.757.757 0 0 1 0-1.06.733.733 0 0 1 1.047 0l3.052 3.093 5.4-6.425a.247.247 0 0 1 .02-.022Z"/>
                            </svg>
                            6 month contract
                        </div>
                        <div class="buyer_seller-item">
                            <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" fill="#4FA7F8"
                                 class="bi bi-check-lg" viewBox="0 0 16 16">
                                <path d="M12.736 3.97a.733.733 0 0 1 1.047 0c.286.289.29.756.01 1.05L7.88 12.01a.733.733 0 0 1-1.065.02L3.217 8.384a.757.757 0 0 1 0-1.06.733.733 0 0 1 1.047 0l3.052 3.093 5.4-6.425a.247.247 0 0 1 .02-.022Z"/>
                            </svg>
                            Situational Coaching And Advice
                        </div>
                        <!--                        <div class="buyer_seller-item">-->
                        <!--                            <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" fill="#4FA7F8" class="bi bi-check-lg" viewBox="0 0 16 16">-->
                        <!--                                <path d="M12.736 3.97a.733.733 0 0 1 1.047 0c.286.289.29.756.01 1.05L7.88 12.01a.733.733 0 0 1-1.065.02L3.217 8.384a.757.757 0 0 1 0-1.06.733.733 0 0 1 1.047 0l3.052 3.093 5.4-6.425a.247.247 0 0 1 .02-.022Z"/>-->
                        <!--                            </svg>-->
                        <!--                            Get Our Help Customizing Templates Based On Your Needs-->
                        <!--                        </div>-->
                        <div class="buyer_seller-item">
                            <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" fill="#4FA7F8"
                                 class="bi bi-check-lg" viewBox="0 0 16 16">
                                <path d="M12.736 3.97a.733.733 0 0 1 1.047 0c.286.289.29.756.01 1.05L7.88 12.01a.733.733 0 0 1-1.065.02L3.217 8.384a.757.757 0 0 1 0-1.06.733.733 0 0 1 1.047 0l3.052 3.093 5.4-6.425a.247.247 0 0 1 .02-.022Z"/>
                            </svg>
                            First Look At New Product Features And Priority Access To New Features
                        </div>
                    </div>
                </div>
                <div class="card_item-agree-btn">
                    <div class="card_item-agree_check">
                        <div class="card_item-agreement">
                            <b-form-checkbox v-model="proPlan" name="check-button">
                                I have read and agree to the
                            </b-form-checkbox>&nbsp; <span @click="buyerAndSeller()"> Agreement</span>
                        </div>
                        <div class="card_item-agreement">
                            <b-form-checkbox v-model="proPlanPolicies" name="check-button">
                                I have read and agree to the
                            </b-form-checkbox>&nbsp; <span @click="liteBillingPolicies()"> Billing Policies</span>
                        </div>
                    </div>

                    <b-button class="subscribe_btn" :disabled="proPlanCheck" @click="subscribePro()">Subscribe
                    </b-button>
                </div>
            </div>
            <div class="card_item">
                <div class="payment_content">
                    <div class="card_item-title">PRO PLUS</div>
                    <div class="card_item-line"></div>
                    <div class="card_item-soon">Coming Soon</div>
                    <div class="card_item-buyer-seller">
                        <div class="buyer_seller-item">
                            <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" fill="#4FA7F8"
                                 class="bi bi-check-lg" viewBox="0 0 16 16">
                                <path d="M12.736 3.97a.733.733 0 0 1 1.047 0c.286.289.29.756.01 1.05L7.88 12.01a.733.733 0 0 1-1.065.02L3.217 8.384a.757.757 0 0 1 0-1.06.733.733 0 0 1 1.047 0l3.052 3.093 5.4-6.425a.247.247 0 0 1 .02-.022Z"/>
                            </svg>
                            Buyer And Seller Leads
                        </div>
                        <div class="buyer_seller-item">
                            <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" fill="#4FA7F8"
                                 class="bi bi-check-lg" viewBox="0 0 16 16">
                                <path d="M12.736 3.97a.733.733 0 0 1 1.047 0c.286.289.29.756.01 1.05L7.88 12.01a.733.733 0 0 1-1.065.02L3.217 8.384a.757.757 0 0 1 0-1.06.733.733 0 0 1 1.047 0l3.052 3.093 5.4-6.425a.247.247 0 0 1 .02-.022Z"/>
                            </svg>
                            Software for Real Estate Agents
                        </div>
                        <div class="buyer_seller-item">
                            <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" fill="#4FA7F8"
                                 class="bi bi-check-lg" viewBox="0 0 16 16">
                                <path d="M12.736 3.97a.733.733 0 0 1 1.047 0c.286.289.29.756.01 1.05L7.88 12.01a.733.733 0 0 1-1.065.02L3.217 8.384a.757.757 0 0 1 0-1.06.733.733 0 0 1 1.047 0l3.052 3.093 5.4-6.425a.247.247 0 0 1 .02-.022Z"/>
                            </svg>
                            Situational Coaching And Advice
                        </div>
                        <!--                        <div class="buyer_seller-item">-->
                        <!--                            <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" fill="#4FA7F8" class="bi bi-check-lg" viewBox="0 0 16 16">-->
                        <!--                                <path d="M12.736 3.97a.733.733 0 0 1 1.047 0c.286.289.29.756.01 1.05L7.88 12.01a.733.733 0 0 1-1.065.02L3.217 8.384a.757.757 0 0 1 0-1.06.733.733 0 0 1 1.047 0l3.052 3.093 5.4-6.425a.247.247 0 0 1 .02-.022Z"/>-->
                        <!--                            </svg>-->
                        <!--                            Get Our Help Customizing Templates Based On Your Needs-->
                        <!--                        </div>-->
                        <div class="buyer_seller-item">
                            <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" fill="#4FA7F8"
                                 class="bi bi-check-lg" viewBox="0 0 16 16">
                                <path d="M12.736 3.97a.733.733 0 0 1 1.047 0c.286.289.29.756.01 1.05L7.88 12.01a.733.733 0 0 1-1.065.02L3.217 8.384a.757.757 0 0 1 0-1.06.733.733 0 0 1 1.047 0l3.052 3.093 5.4-6.425a.247.247 0 0 1 .02-.022Z"/>
                            </svg>
                            First Look At New Product Features And Priority Access To New Features
                        </div>
                        <div class="buyer_seller-item">
                            <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" fill="#4FA7F8"
                                 class="bi bi-check-lg" viewBox="0 0 16 16">
                                <path d="M12.736 3.97a.733.733 0 0 1 1.047 0c.286.289.29.756.01 1.05L7.88 12.01a.733.733 0 0 1-1.065.02L3.217 8.384a.757.757 0 0 1 0-1.06.733.733 0 0 1 1.047 0l3.052 3.093 5.4-6.425a.247.247 0 0 1 .02-.022Z"/>
                            </svg>
                            Executive Assistant To Help You Schedule Appointments
                        </div>
                        <div class="buyer_seller-item">
                            <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" fill="#4FA7F8"
                                 class="bi bi-check-lg" viewBox="0 0 16 16">
                                <path d="M12.736 3.97a.733.733 0 0 1 1.047 0c.286.289.29.756.01 1.05L7.88 12.01a.733.733 0 0 1-1.065.02L3.217 8.384a.757.757 0 0 1 0-1.06.733.733 0 0 1 1.047 0l3.052 3.093 5.4-6.425a.247.247 0 0 1 .02-.022Z"/>
                            </svg>
                            AI Automation To Help You Convert Leads
                        </div>
                    </div>
                </div>
                <div class="card_item-agree-btn">
                    <!--                    <div class="card_item-agreement">-->
                    <!--                        <b-form-checkbox v-model="proPlusPlan" name="check-button">-->
                    <!--                            I have read and agree to the-->
                    <!--                        </b-form-checkbox>&nbsp; <span> Agreement</span>-->
                    <!--                    </div>-->
                    <b-button class="subscribe_btn" disabled>Subscribe</b-button>
                </div>
            </div>
        </div>
        <div class="text_danger" v-if="cardsError">Card does not exist</div>
        <div class="text_danger" v-if="subscribe_error">{{subscribe_error}}</div>
        <div class="subtitle_txt">One-time on-boarding fee due at subscripton start date ($300 for Lite and $600 for
            Pro)
        </div>
        <div class="payment_card-container">
            <div class="title_text">
                <h4 class="subscription_title">SUBSCRIPTION PAYMENT METHOD:</h4>
                <div class="edit_add" id="new_card-btn" @click="addNewCard()">Add Card</div>
            </div>
            <div class="subtitle_text">This includes your subscription, any add-ins and any extra zip code costs.</div>

            <div class="credit_card-items">
                <div class="card_item" v-for="card in allCards">
                    <div class="d-flex align-items-center">
                        <b-form-radio v-model="chosenCard" name="chosenCard"
                                      :value="card.db_id"
                                      :checked="card.db_id == chosenCard"
                                      @change="cardChange"
                        ></b-form-radio>
                        <div class="card_type-container">
                            <div
                                    class="card_type"
                                    :class="{
                                'visa': card.brand == 'Visa',
                                'master': card.brand == 'MasterCard',
                                'american': card.brand == 'American Express'
                            }"
                            >
                            </div>
                        </div>
                        <div class="card_name-number">
                            <div class="card_name">{{card.name}}</div>
                            <div class="card_number">**** **** ****
                                {{card.last4}}
                            </div>
                        </div>
                    </div>
                    <div class="card_date"> {{card.exp_month < 10 ? '0' + card.exp_month: card.exp_month }} /
                        {{card.exp_year}}
                        <div class="card_delete hide"
                             @click="deleteCard(card)">
                            <svg xmlns="http://www.w3.org/2000/svg" width="22.824" height="26" viewBox="0 0 22.824 26">
                                <g id="Group_8226" data-name="Group 8226" transform="translate(-3233.616 -546.979)">
                                    <path id="Path_304" data-name="Path 304"
                                          d="M3259.121,621.924c3.112,0,6.224.006,9.336-.007.37,0,.481.061.445.472-.311,3.52-.593,7.042-.883,10.563-.14,1.7-.266,3.4-.424,5.1a2.312,2.312,0,0,1-2.5,2.248q-6.013.006-12.026,0a2.306,2.306,0,0,1-2.488-2.264c-.326-3.841-.63-7.683-.946-11.525-.114-1.382-.219-2.764-.36-4.143-.038-.367.048-.457.41-.455C3252.829,621.93,3255.975,621.924,3259.121,621.924Zm-5.315,2.992h-.01q.353,5.593.706,11.186c.052.828.3,1.18.821,1.149s.748-.424.7-1.234q-.3-4.885-.606-9.769c-.043-.692-.076-1.385-.148-2.074a.732.732,0,0,0-.812-.719.759.759,0,0,0-.682.853C3253.774,624.512,3253.794,624.714,3253.806,624.917Zm10.567.064h-.007c.013-.219.034-.439.037-.658a.764.764,0,0,0-.668-.865.782.782,0,0,0-.835.764c-.032.234-.036.472-.051.708q-.187,2.985-.373,5.971c-.112,1.8-.229,3.609-.331,5.415-.031.554.25.906.7.937s.78-.3.813-.835Q3264.018,630.7,3264.373,624.98Zm-6.045,5.387q0,2.839,0,5.678c0,.152-.005.305.009.456a.749.749,0,0,0,1.5.022,2.57,2.57,0,0,0,.011-.3q0-5.856,0-11.712a2.93,2.93,0,0,0-.009-.3.76.76,0,0,0-.739-.751.75.75,0,0,0-.759.729,4.168,4.168,0,0,0-.011.456Q3258.328,627.5,3258.328,630.368Z"
                                          transform="translate(-14.06 -67.329)" fill="white"/>
                                    <path id="Path_305" data-name="Path 305"
                                          d="M3245.029,553.074q-4.873,0-9.746,0a1.523,1.523,0,0,1-1.665-1.469,1.5,1.5,0,0,1,1.559-1.573q2.03-.023,4.061,0c.34,0,.461-.073.467-.44.031-1.791.889-2.613,2.683-2.613q2.716,0,5.432,0a2.289,2.289,0,0,1,2.528,2.509c0,.462.141.556.572.546,1.269-.029,2.538-.013,3.807-.009a1.534,1.534,0,1,1,0,3.046Q3249.877,553.078,3245.029,553.074Zm0-3.048c1.131,0,2.263,0,3.395,0,.135,0,.3.074.393-.124a.981.981,0,0,0-.8-1.387c-1.993-.014-3.986,0-5.979-.006a.768.768,0,0,0-.593.238,1.159,1.159,0,0,0-.18,1.151c.08.282.444.121.675.125C3242.969,550.036,3244,550.026,3245.029,550.026Z"
                                          fill="white"/>
                                </g>
                            </svg>
                        </div>
                    </div>
                    <div class="card_update">
                        <!--                        <div class="card_edit" @click="editCard(card)"></div>-->
                        <div class="card_delete" id="delete_btn"
                             @click="deleteCard(card)">
                            <svg xmlns="http://www.w3.org/2000/svg" width="22.824" height="26" viewBox="0 0 22.824 26">
                                <g id="Group_8226" data-name="Group 8226" transform="translate(-3233.616 -546.979)">
                                    <path id="Path_304" data-name="Path 304"
                                          d="M3259.121,621.924c3.112,0,6.224.006,9.336-.007.37,0,.481.061.445.472-.311,3.52-.593,7.042-.883,10.563-.14,1.7-.266,3.4-.424,5.1a2.312,2.312,0,0,1-2.5,2.248q-6.013.006-12.026,0a2.306,2.306,0,0,1-2.488-2.264c-.326-3.841-.63-7.683-.946-11.525-.114-1.382-.219-2.764-.36-4.143-.038-.367.048-.457.41-.455C3252.829,621.93,3255.975,621.924,3259.121,621.924Zm-5.315,2.992h-.01q.353,5.593.706,11.186c.052.828.3,1.18.821,1.149s.748-.424.7-1.234q-.3-4.885-.606-9.769c-.043-.692-.076-1.385-.148-2.074a.732.732,0,0,0-.812-.719.759.759,0,0,0-.682.853C3253.774,624.512,3253.794,624.714,3253.806,624.917Zm10.567.064h-.007c.013-.219.034-.439.037-.658a.764.764,0,0,0-.668-.865.782.782,0,0,0-.835.764c-.032.234-.036.472-.051.708q-.187,2.985-.373,5.971c-.112,1.8-.229,3.609-.331,5.415-.031.554.25.906.7.937s.78-.3.813-.835Q3264.018,630.7,3264.373,624.98Zm-6.045,5.387q0,2.839,0,5.678c0,.152-.005.305.009.456a.749.749,0,0,0,1.5.022,2.57,2.57,0,0,0,.011-.3q0-5.856,0-11.712a2.93,2.93,0,0,0-.009-.3.76.76,0,0,0-.739-.751.75.75,0,0,0-.759.729,4.168,4.168,0,0,0-.011.456Q3258.328,627.5,3258.328,630.368Z"
                                          transform="translate(-14.06 -67.329)" fill="white"/>
                                    <path id="Path_305" data-name="Path 305"
                                          d="M3245.029,553.074q-4.873,0-9.746,0a1.523,1.523,0,0,1-1.665-1.469,1.5,1.5,0,0,1,1.559-1.573q2.03-.023,4.061,0c.34,0,.461-.073.467-.44.031-1.791.889-2.613,2.683-2.613q2.716,0,5.432,0a2.289,2.289,0,0,1,2.528,2.509c0,.462.141.556.572.546,1.269-.029,2.538-.013,3.807-.009a1.534,1.534,0,1,1,0,3.046Q3249.877,553.078,3245.029,553.074Zm0-3.048c1.131,0,2.263,0,3.395,0,.135,0,.3.074.393-.124a.981.981,0,0,0-.8-1.387c-1.993-.014-3.986,0-5.979-.006a.768.768,0,0,0-.593.238,1.159,1.159,0,0,0-.18,1.151c.08.282.444.121.675.125C3242.969,550.036,3244,550.026,3245.029,550.026Z"
                                          fill="white"/>
                                </g>
                            </svg>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <!--New Card modal start-->
        <b-modal ref="card-modal" centered size="md" hide-header hide-footer>
            <div class="close_btn_content" @click="cardModal()">
                <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="#9E9E9E" class="bi bi-x-lg"
                     viewBox="0 0 16 16">
                    <path d="M1.293 1.293a1 1 0 0 1 1.414 0L8 6.586l5.293-5.293a1 1 0 1 1 1.414 1.414L9.414 8l5.293 5.293a1 1 0 0 1-1.414 1.414L8 9.414l-5.293 5.293a1 1 0 0 1-1.414-1.414L6.586 8 1.293 2.707a1 1 0 0 1 0-1.414z"/>
                </svg>
            </div>
            <div class="modal_content">
                <div class="modal_card">
                    <div class="modal_card-icon"></div>
                    <div class="modal_card-number" v-if="credit_card.cardNumber">
                        {{getCardNumber(credit_card.cardNumber)}}
                    </div>
                    <div v-else class="modal_card-number">XXXX XXXX XXXX XXXX</div>
                    <div class="modal_card-date">
                        <span v-if="credit_card.cardMonth">{{credit_card.cardMonth}}</span>
                        <span v-else>MM</span>/
                        <span v-if="credit_card.cardYear">{{credit_card.cardYear}}</span>
                        <span v-else>YYYY</span>
                    </div>
                    <!--                    <div class="modal_card-date" v-else>MM/YYYY</div>-->
                    <div class="modal_card-name" v-if="credit_card.cardName">{{credit_card.cardName}}</div>
                    <div class="modal_card-name" v-else>FIRST LASTNAME</div>
                </div>
                <div class="modal_input">
                    <div class="input_item">
                        <b-form-input
                                placeholder="Card Name"
                                v-model="credit_card.cardName"
                                onKeyPress="if(this.value.length==26) return false;"
                                type="text"
                                trim
                        ></b-form-input>
                        <div class="mt-1">
                        </div>
                    </div>
                    <div class="input_item">
                        <b-form-input
                                placeholder="Card Number"
                                v-model="credit_card.cardNumber"
                                type="number"
                                pattern="/^-?\d+\.?\d*$/"
                                onKeyPress="if(this.value.length==16) return false;"
                                trim
                        ></b-form-input>
                        <div class="mt-1">
                        </div>
                    </div>
                    <div class="input_item">
                        <div class="date_inputs">
                            <b-form-select v-model="credit_card.cardMonth">
                                <template #first>
                                    <b-form-select-option value="" disabled selected>MM
                                    </b-form-select-option>
                                </template>
                                <option
                                        v-for="option in 12"
                                        :value="option"
                                        :title="option || null"
                                >
                                    {{option}}
                                </option>
                            </b-form-select>
                            /
                            <b-form-input
                                    placeholder="YYYY"
                                    v-model="credit_card.cardYear"
                                    type="number"
                                    pattern="/^-?\d+\.?\d*$/"
                                    onKeyPress="if(this.value.length==4) return false;"
                                    trim
                            ></b-form-input>
                        </div>
                        <div class="mt-1">
                        </div>
                    </div>
                    <div class="input_item">
                        <b-form-input
                                placeholder="CVV"
                                v-model="credit_card.cardCvv"
                                type="number"
                                pattern="/^-?\d+\.?\d*$/"
                                onKeyPress="if(this.value.length==4) return false;"
                                trim
                        ></b-form-input>
                        <div class="mt-1">
                        </div>
                    </div>
                </div>
                <div class="modal_btn">
                    <b-button class="save_btn" @click="saveCard()">Save Card</b-button>
                </div>
                <span class="text_danger mt-1" v-if="cardError">{{cardError}}</span>
            </div>
        </b-modal>
        <!--New Card modal end-->

        <!--Delete card modal start-->
        <b-modal ref="delete_card_modal" centered hide-header hide-footer hide-header-close>
            <div class="delete-modal_content">
                <b-icon icon="x" class="x_btn" aria-hidden="true" @click="deleteModal()"></b-icon>
                <div class="d-flex flex-column">
                    <div class="modal_title">
                        You are about to delete this Credit Card
                    </div>
                    <div class="modal_text">
                        Do you want to proceed?
                    </div>
                    <div class="modal_buttons">
                        <div class="cancel_btn" @click="deleteModal()">Cancel</div>
                        <b-button class="done_btn ml-4" @click="cardRemove()">Done</b-button>
                    </div>
                </div>
            </div>
        </b-modal>
        <!--Delete card modal end-->

        <!--Delete card modal start-->
        <b-modal ref="success_modal" centered hide-header hide-footer hide-header-close>
            <div class="delete-modal_content">
                <b-icon icon="x" class="x_btn" aria-hidden="true" @click="successModal()"></b-icon>
                <div class="d-flex flex-column">
                    <div class="modal_title">
                        SUBSCRIPTION SUCCESS
                    </div>
                    <div class="congratulations">Congratulations!</div>
                    <div class="modal_text">
                         You have successfully subscribed to Rezi Leads! We will start tracking down
                        leads in your area. Please check back every few days for new leads!
                    </div>
                    <div class="modal_buttons">
                        <b-button class="done_btn ml-4" @click="successModal()">Done</b-button>
                    </div>
                </div>
            </div>
        </b-modal>
        <!--Delete card modal end-->

    </div>
</template>

<script>
    import {mapActions} from "vuex";

    export default {
        name: "Payment",
        data() {
            return {
                borderPosition: true,
                buyer_seller: 0,
                options: [
                    {text: 'Buyer Leads', value: 0},
                    {text: 'Seller Leads', value: 1},
                ],
                // card_number: null,
                litePlan: false,
                litePlanPolicies: false,
                litePlanData: '',
                proPlan: false,
                proPlanPolicies: false,
                proPlanData: '',
                proPlusPlan: false,
                cardsError: false,
                credit_card: {
                    cardName: '',
                    cardNumber: '',
                    cardMonth: '',
                    cardYear: '',
                    cardCvv: '',
                },
                chosenCard: null,
                delete_card_id: null,
                cardError: '',
                subscribe_error: '',
                allCards: []
            }
        },
        computed: {
            litePlanCheck() {
                if (this.litePlan && this.litePlanPolicies) {
                    return false
                } else {
                    return true
                }
            },
            proPlanCheck() {
                if (this.proPlan && this.proPlanPolicies) {
                    return false
                } else {
                    return true
                }
            }
        },
        created() {
            alert.$on("clickOn", border => {
                this.borderPosition = border;
            })
            this.getPlans()
                .then(res => {
                    if (res.status === 200) {
                        this.litePlanData = res.data.success[0]
                        this.proPlanData = res.data.success[1]
                    }
                })
            this.getCreditCards()
                .then(res => {
                    if (res.status === 200) {
                        this.allCards = res.data.success
                        for (const property in this.allCards) {
                            if (this.allCards[property].default == 1) {
                                this.chosenCard = this.allCards[property].db_id
                            }
                        }
                    }
                })

        },
        methods: {
            ...mapActions([
                'getPlans',
                'getCreditCards',
                'addNewCreditCard',
                'removeCreditCard',
                'changeActiveCard',
                'userSubscribe',
                'getUserData'
            ]),
            successModal() {
                this.$refs['success_modal'].toggle('#success_m')
            },
            buyerOrSeller() {
                let route = this.$router.resolve({path: '/buyer_or_seller'});
                window.open(route.href, '_blank');
            },
            buyerAndSeller() {
                let route = this.$router.resolve({path: '/buyer_and_seller'});
                window.open(route.href, '_blank');
            },
            liteBillingPolicies() {
                let route = this.$router.resolve({path: '/billing_policies'});
                window.open(route.href, '_blank');

            },
            getCardNumber(value) {
                var v = value.replace(/\s+/g, '').replace(/[^0-9]/gi, '')
                var matches = v.match(/\d{4,16}/g);
                var match = matches && matches[0] || ''
                var parts = []
                for (let i = 0, len = match.length; i < len; i += 4) {
                    parts.push(match.substring(i, i + 4))
                }
                if (parts.length) {
                    return parts.join(' ')
                } else {
                    return value
                }
            },
            // getCardDate(x) {
            //     return x.replace(
            //         /^([1-9]\/|[2-9])$/g, '0$1/'
            //     ).replace(
            //         /^(0[1-9]|1[0-2])$/g, '$1/'
            //     ).replace(
            //         /^([0-1])([3-9])$/g, '0$1/$2'
            //     ).replace(
            //         /^(0?[1-9]|1[0-2])([0-9]{2})$/g, '$1/$2'
            //     ).replace(
            //         /^([0]+)\/|[0]+$/g, '0'
            //     ).replace(
            //         /[^\d\/]|^[\/]*$/g, ''
            //     ).replace(
            //         /\/\//g, '/'
            //     );
            // },
            cardChange(args) {
                this.changeActiveCard(args)
                this.getCreditCards()
                    .then(res => {
                        if (res.status === 200) {
                            this.allCards = res.data.success
                        }
                    })
            },
            addNewCard() {
                this.cardError = ''
                this.credit_card.cardName = ''
                this.credit_card.cardNumber = ''
                this.credit_card.cardMonth = ''
                this.credit_card.cardYear = ''
                this.credit_card.cardCvv = ''
                this.cardModal()
            },
            cardModal() {
                this.$refs['card-modal'].toggle('#new_card-btn')
            },
            saveCard() {
                this.cardError = ''
                this.addNewCreditCard(this.credit_card)
                    .then(res => {
                        if (res.status === 200) {
                            this.cardModal()
                            this.getCreditCards()
                                .then(res => {
                                    if (res.status === 200) {
                                        this.allCards = res.data.success
                                        if (this.allCards) {
                                            this.cardsError = false
                                        }
                                    }
                                })
                        } else {
                            this.cardError = res.response.data.error
                        }
                    })
            },
            deleteCard(card) {
                this.delete_card_id = card.db_id
                this.deleteModal()
            },
            deleteModal() {
                this.$refs['delete_card_modal'].toggle('#delete_btn')
            },
            cardRemove() {
                this.removeCreditCard(this.delete_card_id)
                    .then(res => {
                        if (res.status === 200) {
                            this.deleteModal()
                            this.getCreditCards()
                                .then(res => {
                                    if (res.status === 200) {
                                        this.allCards = res.data.success
                                    }
                                })
                        }
                    })

            },
            getPrice(data) {
                let a = data.subscription_unit_amount - data.one_time_unit_amount
                return a / 100
            },
            subscribeLite() {
                let sendData = {
                    plan_id: this.litePlanData.id,
                    subscription_type: this.buyer_seller
                }
                this.cardsError = false
                this.cardError = ''
                if (this.allCards) {
                    this.userSubscribe(sendData)
                        .then(res => {
                            if (res.status === 200) {
                                this.successModal()
                                this.getUserData()
                            } else {
                                this.subscribe_error = res.response.data.error

                            }
                        })
                } else {
                    this.cardsError = true
                }
            },
            subscribePro() {
                this.cardsError = false
                this.cardError = ''
                let sendData = {
                    plan_id: this.proPlanData.id,
                    subscription_type: null
                }
                if (this.allCards) {
                    this.userSubscribe(sendData)
                        .then(res => {
                            if (res.status === 200) {
                                this.successModal()
                                this.getUserData()
                            } else {
                                this.subscribe_error = res.response.data.error
                            }
                        })
                } else {
                    this.cardsError = true
                }

            }
        }
    }
</script>

<style scoped lang="scss">
    .page {
        &_container {
            display: flex;
            flex-direction: column;
            align-items: center;
            max-width: 1700px;
            width: 100%;
            margin: 0 auto;
            transition: .4s;
            @media screen and (max-width: 1680px) {
                max-width: 1200px;
            }

            & .cards_content {
                display: flex;
                justify-content: center;
                padding: 30px 30px 20px;
                @media screen and (max-width: 1680px) {
                    padding: 20px;
                }
                @media screen and (max-width: 1440px) {
                    padding: 20px 0;
                }
                @media screen and (max-width: 1280px) {
                    flex-wrap: wrap;
                }


                & .card_item {
                    width: 400px;
                    /*height: 368px;*/
                    display: flex;
                    align-items: center;
                    justify-content: space-between;
                    flex-direction: column;
                    background: #FFFFFF 0 0 no-repeat padding-box;
                    box-shadow: 0 3px 6px #00000029;
                    border-radius: 10px;
                    margin: 30px 20px;
                    cursor: pointer;
                    transition: .4s;
                    @media screen and (max-width: 1680px) {
                        width: 340px;
                    }
                    @media screen and (max-width: 1680px) {
                        margin: 15px 20px;
                    }


                    &:hover {
                        transform: scale(1.1, 1.1);
                        transition: .4s;

                        .card_item-line {
                            background: #C2E2FF;
                        }
                    }

                    &:active {
                        box-shadow: none;
                        border: none;
                    }

                    &:focus {
                        box-shadow: none;
                        border: none;
                    }

                    & .payment_content {
                        display: flex;
                        flex-direction: column;
                        align-items: center;
                        padding: 0 30px;
                    }

                    &-title {
                        display: flex;
                        justify-content: center;
                        padding: 30px 0 5px;
                        font-weight: 500;
                        color: #095498;
                        font-size: 18px;
                        line-height: 21px;
                    }

                    &-line {
                        width: 60px;
                        height: 4px;
                        background: #4FA7F8;
                        border-radius: 3px;
                    }

                    &-soon {
                        width: 270px;
                        height: 50px;
                        color: $main_blue;
                        font-size: 23px;
                        font-weight: 600;
                        background: #DAEDFF;
                        border-radius: 10px;
                        display: flex;
                        justify-content: center;
                        align-items: center;
                        margin: 25px 0 10px;
                    }

                    &-price {
                        padding: 25px 0 0;
                        color: $black;
                        font-weight: 600;
                        font-size: 30px;
                    }

                    &-plus {
                        color: #7091A7;
                        font-size: 14px;
                        margin: 5px 0;
                    }

                    &-agreement {
                        font-size: 13px;
                        display: flex;
                        align-items: center;
                        padding: 2px 0;

                        & span {
                            line-height: 17px;
                            color: $light_blue;
                            text-decoration: underline;
                        }
                    }

                    &-text {
                        color: #9C9C9C;
                        font-size: 14px;
                        line-height: 14px;
                    }

                    &-agree-btn {
                        display: flex;
                        flex-direction: column;
                        align-items: center;
                    }

                    &-agree_check {
                        display: flex;
                        flex-direction: column;
                    }

                    &-buyer-seller {
                        padding: 10px 0 20px;

                        & .buyer_seller-item {
                            font-size: 14px;
                            margin: 4px 0;

                            & svg {
                                margin-right: 5px;
                            }
                        }
                    }

                    &-radio-group {
                        display: flex;
                        flex-direction: column;
                        align-items: center;
                    }

                    & .subscribe_btn {
                        width: 180px;
                        height: 46px;
                        color: $white;
                        background: $light_blue;
                        display: flex;
                        justify-content: center;
                        align-items: center;
                        border: none;
                        margin: 22px 0;

                        &:active,
                        &:focus {
                            box-shadow: none;
                            border: none;
                            outline: none;
                        }
                    }
                }
            }

            & .payment_card-container {
                max-width: 1080px;
                width: 100%;
                height: 310px;
                border: 1px solid $light_blue;
                background: #FFFFFF 0 0 no-repeat padding-box;
                border-radius: 10px;
                margin: 10px 0 80px;
                padding: 25px 35px;
                @media screen and (max-width: 1440px) {
                    max-width: 960px;
                }
                @media screen and (max-width: 1280px) {
                    max-width: 900px;
                    margin: 10px 0 40px;
                    padding: 20px 30px;
                }
                @media screen and (max-width: 1024px) {
                    max-width: 730px;
                }
                @media screen and (max-width: 1024px) {
                    max-width: 460px;
                }
                @media screen and (max-width: 425px) {
                    max-width: 400px;
                    padding: 20px;
                }

                & .title_text {
                    display: flex;
                    justify-content: space-between;

                    & .subscription_title {
                        color: $main_blue;
                        text-transform: uppercase;
                        font-size: 18px;
                        font-weight: 500;
                        @media screen and (max-width: 425px) {
                            font-size: 15px;
                        }
                    }

                    & .edit_add {
                        font-size: 14px;
                        font-weight: 500;
                        color: #00C2FF;
                        cursor: pointer;
                    }
                }

                & .subtitle_text {
                    color: #798387;
                    font-size: 14px;
                }

                & .input_item {
                    padding: 25px 0;

                    & label {
                        color: $black;
                        font-size: 16px;
                    }

                    & input {
                        max-width: 430px;
                        width: 100%;
                        height: 40px;
                        background: #FFFFFF 0 0 no-repeat padding-box;
                        border: 1px solid #798387;
                        border-radius: 5px;
                    }
                }

                & .save_button-text {
                    display: flex;
                    align-items: center;

                    & .save_btn {
                        width: 135px;
                        height: 46px;
                        display: flex;
                        justify-content: center;
                        align-items: center;
                        color: $white;
                        background: $light_blue;
                        border: none;

                        &:focus {
                            box-shadow: none;
                        }
                    }

                    & .save_text {
                        padding: 0 28px;
                        color: #798387;
                        font-size: 14px;
                    }
                }

                .credit_card-items {
                    padding: 10px 0;
                    max-height: 200px;
                    overflow-y: scroll;
                    white-space: nowrap;
                    @media screen and (max-width: 425px) {

                    }

                    &::-webkit-scrollbar {
                        width: 5px;
                        height: 4px;
                    }

                    &::-webkit-scrollbar-track {
                        border-radius: 5px;
                    }

                    &::-webkit-scrollbar-thumb {
                        background: grey;
                        border-radius: 5px;
                    }

                    & .card {
                        &_item {
                            max-width: 960px;
                            width: 100%;
                            height: 82px;
                            border-radius: 10px;
                            background: $light_blue;
                            display: flex;
                            align-items: center;
                            justify-content: space-between;
                            margin: 10px 0;
                            padding: 10px 20px;
                            @media screen and (max-width: 768px) {
                                height: unset;
                                flex-direction: column;
                                align-items: unset;
                            }
                            @media screen and (max-width: 425px) {
                                padding: 10px 15px;
                            }
                            @media screen and (max-width: 425px) {
                                padding: 10px;
                            }

                            ::v-deep .custom-control-input:checked ~ .custom-control-label::before {
                                border-color: $main_blue;
                                background-color: $main_blue;
                            }

                            ::v-deep .custom-control-label {
                                &:after {
                                    width: 16px !important;
                                    height: 16px !important;
                                    cursor: pointer;
                                }

                                &:before {
                                    width: 16px !important;
                                    height: 16px !important;
                                    cursor: pointer;
                                }
                            }
                        }

                        &_type {
                            width: 74px;
                            height: 37px;
                            background: url("~@/assets/images/credit-card.svg") no-repeat center;
                            background-size: contain;

                            &-container {
                                padding: 5px 20px;
                                @media screen and (max-width: 768px) {
                                    padding: 5px 15px;
                                }
                                @media screen and (max-width: 425px) {
                                    padding: 5px 15px 5px 5px;
                                }
                                @media screen and (max-width: 375px) {
                                    padding: 5px 10px 5px 0;
                                }
                            }

                            &.visa {
                                background: url("~@/assets/images/visa.png");
                            }

                            &.master {
                                background: url("~@/assets/images/master.png");
                            }

                            &.american {
                                background: url("~@/assets/images/american.png");
                            }
                        }

                        &_name-number {
                            height: 55px;
                            display: flex;
                            font-weight: 600;
                            color: $white;
                            flex-direction: column;
                            justify-content: space-between;
                            @media screen and (max-width: 375px) {
                                font-size: 14px;
                            }
                        }

                        &_date {
                            font-weight: 600;
                            color: $white;
                            @media screen and (max-width: 768px) {
                                display: flex;
                                justify-content: space-between;
                                padding: 5px 0 10px 40px;
                            }
                            @media screen and (max-width: 375px) {
                                font-size: 14px;
                            }
                        }

                        &_update {
                            width: 20px;
                            display: flex;
                            align-items: center;
                            justify-content: space-between;
                            @media screen and (max-width: 768px) {
                                display: none;
                            }
                            @media screen and (max-width: 425px) {
                                flex-direction: column;
                                width: 30px;
                                height: 50px;
                            }
                            @media screen and (max-width: 375px) {
                                width: 25px;
                            }
                        }

                        &_edit {
                            width: 20px;
                            height: 20px;
                            background: url("~@/assets/images/edit_icon.svg") no-repeat center;
                            background-size: contain;
                            cursor: pointer;
                        }

                        &_delete {
                            width: 22px;
                            height: 28px;
                            cursor: pointer;
                            &.hide {
                                display: none;
                                @media screen and (max-width: 768px) {
                                    display: block;
                                }
                            }
                        }
                    }
                }
            }

            & .subtitle_txt {
                margin-bottom: 10px;
                color: $grey;
                font-size: 18px;
                font-weight: 500;
                text-align: center;
                @media screen and (max-width: 1024px) {
                    font-size: 16px;
                }
                @media screen and (max-width: 768px) {
                    padding: 0 20px;
                }
            }
        }

        &_head {
            width: 100%;
            display: flex;
            justify-content: center;
            align-items: center;
            padding: 30px 30px 5px;

            &-item {
                display: flex;
                align-items: center;
            }

            &-title {
                color: $main_blue;
                font-size: 25px;
                font-weight: 500;
                letter-spacing: 0;
                text-transform: uppercase;
            }
        }
    }

    /* modal */
    ::v-deep .modal-dialog {
        min-width: 520px;
        /*@media screen and (max-width: 600px) {*/
        /*    min-width: 420px;*/
        /*}*/
        @media screen and (max-width: 425px) {
            min-width: unset;
        }
    }

    ::v-deep .modal-content {
        border: none;
    }

    .close_btn_content {
        position: absolute;
        display: flex;
        justify-content: flex-end;
        top: 20px;
        right: 25px;
        cursor: pointer;
    }

    .modal_content {
        padding: 50px 70px 30px;
        border: none;
        display: flex;
        flex-direction: column;
        align-items: center;
        @media screen and (max-width: 425px) {
            padding: 30px 15px 30px;
        }

        & .modal_card {
            width: 380px;
            height: 232px;
            border-radius: 10px;
            background: $light_blue;
            padding: 30px;
            margin-bottom: 10px;
            @media screen and (max-width: 425px) {
                width: 340px;
                height: 190px;
                @media screen and (max-width: 425px) {
                    padding: 15px;
                }
            }

            &-icon {
                width: 50px;
                height: 34px;
                background: url("~@/assets/images/credit-card.svg") no-repeat center;
                background-size: contain;
            }

            &-number {
                margin: 12px 0;
                width: 100%;
                font-size: 24px;
                color: $white;
                font-weight: 500;
                letter-spacing: 2px;
                @media screen and (max-width: 425px) {
                    font-size: 20px;
                }
            }

            &-date {
                display: flex;
                justify-content: flex-end;
                padding: 0 60px;
                font-size: 24px;
                color: $white;
                font-weight: 500;
                letter-spacing: 2px;
                @media screen and (max-width: 425px) {
                    font-size: 20px;
                    padding: 0 25px;
                }
            }

            &-name {
                margin-top: 20px;
                width: 100%;
                font-size: 24px;
                color: $white;
                font-weight: 500;
                letter-spacing: 2px;
                overflow-x: scroll;
                white-space: nowrap;
                @media screen and (max-width: 425px) {
                    font-size: 20px;
                    margin-top: 15px;
                }

                &::-webkit-scrollbar {
                    width: 5px;
                    height: 4px;
                }

                &::-webkit-scrollbar-track {
                    border-radius: 5px;
                }

                &::-webkit-scrollbar-thumb {
                    background: grey;
                    border-radius: 5px;
                }
            }
        }

        & .modal_input {
            padding-bottom: 15px;
            width: 100%;

            & .input_item {
                margin: 8px 0;

                & .date_inputs {
                    width: 100%;
                    height: 44px;
                    display: flex;
                    align-items: center;
                    border: 1px solid #9E9E9E;
                    border-radius: 10px;

                    & input {
                        border: none;
                        height: 42px;
                        width: 75px;

                        &:active,
                        &:focus {
                            border: none;
                            box-shadow: none;
                            outline: none;
                            background: transparent;
                        }
                    }

                    & select {
                        width: 50px;
                        height: 42px;
                        border: none;
                        background: transparent;
                        border-radius: 5px;
                        padding-right: 5px;

                        &:focus {
                            outline: none;
                            box-shadow: none;
                        }
                    }
                }

                & input {
                    background: $white;
                    width: 100%;
                    height: 44px;
                    border: 1px solid #9E9E9E;
                    border-radius: 10px;
                    padding-left: 20px;

                    &:focus {
                        box-shadow: none;
                        border: 1px solid $light_blue;
                        border-radius: 10px;
                    }
                }

                input[type=number] {
                    -moz-appearance: textfield !important;
                }

                input::-webkit-outer-spin-button,
                input::-webkit-inner-spin-button {
                    -webkit-appearance: none !important;
                    margin: 0 !important;
                }
            }
        }

        & .modal_btn {
            width: 100%;
            display: flex;
            justify-content: center;

            & .save_btn {
                background: $light_blue;
                border-radius: 8px;
                width: 190px;
                height: 48px;
                display: flex;
                align-items: center;
                justify-content: center;
                color: $white;
                font-weight: 600;
                border: none;
            }
        }
    }

    /* modal */

    /* delete modal start*/
    ::v-deep .delete-modal-content {
        border: none !important;
        width: 600px;

        ::v-deep .modal-body {
            width: 600px !important;
        }
    }

    ::v-deep .delete-modal_content {
        border: none !important;
        width: 490px;
        padding: 0 10px;
        position: relative;
        @media screen and (max-width: 425px) {
            max-width: 370px;
            width: 100%;
        }

        ::v-deep .modal-body {
            width: 520px !important;
        }

        & .x_btn {
            position: absolute;
            top: 0;
            right: 0;
            width: 25px;
            height: 25px;
            cursor: pointer;
            color: $grey;
        }

        & .modal_title {
            width: 100%;
            padding: 20px 0;
            font-size: 22px;
            color: $darkBlue;
            border-bottom: 1px solid $light_blue;
            @media screen and (max-width: 425px) {
                font-size: 16px;
                font-weight: 600;
            }

            &.attachment {
                padding-left: 10px;
                color: $light_blue;
            }
        }
        & .congratulations {
            padding-top: 10px;
            color: $main_blue;
            font-size: 28px;
            font-weight: 600;
            text-align: center;
        }

        & .modal_text {
            font-size: 16px;
            color: $light_blue;
            padding: 25px 0;
            @media screen and (max-width: 425px) {
                padding: 20px 0;
            }
        }

        & .modal_buttons {
            display: flex;
            justify-content: flex-end;
            align-items: center;
            padding: 15px 0 30px;

            & .cancel_btn {
                padding: 10px 0;
                cursor: pointer;
                background: $light_blue;
                border-radius: 8px;
                width: 120px;
                height: 46px;
                display: flex;
                align-items: center;
                justify-content: center;
                color: $white;
                font-weight: 600;
                border: none;
                @media screen and (max-width: 425px) {
                    width: 100px;
                    height: 40px;
                }
            }

            & .done_btn {
                width: 120px;
                height: 46px;
                background: $light_blue;
                border-radius: 8px;
                color: $white;
                font-weight: 600;
                border: none;
                display: flex;
                justify-content: center;
                align-items: center;
                cursor: pointer;
                @media screen and (max-width: 425px) {
                    width: 100px;
                    height: 40px;
                }
            }
        }

        & .description_content {
            padding: 10px 0 5px;

            & .description_subtitle {
                font-weight: 500;
                font-size: 18px;
                padding: 0 15px;
                color: $main_blue;
            }

            & .description_text {
                padding: 10px 20px 5px;
                border-bottom: 1px solid #e9e9e9;
            }
        }
    }

    /* delete modal end*/
</style>